/**
 * Samepagenet js Functions
 * @author mehidi <mehidi@g.tebura.ninja>
 * @package Samepagenet
 * @version 1.0.0
 */

;(function( $, window, document ){
	"use strict";
	// remove preload after page load
	$(window).on('load',function() {
		// $(".video-container-wrapper video").append('<source src="banner-video.mp4" type="video/mp4">');
		$('#preloader').fadeOut(2000, function() {
			$('body').css('overflow','visible');
			$(this).remove();
		});
		// load banner background video
	});

	// sameheight function
	var sameHeight = function(target) {
		var highest = 0,
			windowWidth = $(window).outerWidth();
		target.css('min-height', highest);
		if (windowWidth >= 768) {
			target.each(function() {
				var high = $(this).outerHeight();
				if (highest < high) {
					highest = high;
				}
			});
			target.css('min-height', highest);
		}
	};

	// validate function
	var validateForm = function(form){
		if( $(form).length > 0  ) {
			$(form).validate();
			$(form+" .form-field[required]").each(function(){
				if( $(this).parents('.form-group').find('.required-sign').length === 0 ){
					$(this).parents('.form-group').find('label').append("<span class='required-sign'>※</span>");
				}
				$(this).on("keyup", function(){
				    if($(form).valid()){
						$(".btn-submit").removeAttr("disabled");
				   	}else {
				   		$(".btn-submit").attr("disabled", "disabled");
				   	}
			 	});
			});
		}
	};

	// scroll effect function for adding enimation when users enter specific section or content
	var scrollEffect = function(){
		var selectors = $(".has_scroll_effect");
		$(window).scroll(function() {
			selectors.each(function(){
				var animateStatus = false,
					top_of_element = $(this).offset().top,
			    	bottom_of_element = $(this).offset().top + $(this).outerHeight(),
			    	bottom_of_screen = $(window).scrollTop() + window.innerHeight,
			    	top_of_screen = $(window).scrollTop(),
			    	animate_class = $(this).attr('data-animate-class'),
			    	animate_duration = $(this).attr('data-animate-duration');

			    if( (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) && animateStatus === false ){
			    	if( typeof animate_class !== typeof undefined && animate_class !== false ){
			    		$(this).addClass(animate_class);
			    	}else {
			    		$(this).addClass('fadeInUp');
			    	}
			    	if( typeof animate_duration !== typeof undefined && animate_duration !== false ){
			    		$(this).css('animationDuration', animate_duration);
			    	}

			        animateStatus = true;
			    }

			});
		});
	};

	// check is dom ready
	$(document).ready(function(){

		// active scroll efect function
		scrollEffect();

		// validate form
		validateForm("#contact_form form");

		// scrollTo object
		var scrollTo = {
			target: $('.site-nav a, .go-up, .scroll-down '),
			root: $('html, body'),
			topPosition: ( $(window).innerWidth() > 767 ? 87 : 0 ),
			init: function() {
				scrollTo.target.on('click', function( e ) {
					var href = $(this).attr('href');
					if( href === "#" || href.indexOf( '#' ) === -1 || href.indexOf( '#' ) > 0 ) {
						return;
					}
					if( $(href).length > 0 ){
						scrollTo.click($(href));
						$('body').removeClass('active-menu');
						$('.collapse-bar').removeClass('open');
						$(this).addClass('active');
					}
					e.preventDefault();
				});
			},
			click: function(element) {
				scrollTo.root.animate({
					scrollTop: element.offset().top - scrollTo.topPosition
				}, 500);
			}
		};
		// initialize scrollTo
		scrollTo.init();
		$('.lang-switcher').find('a[href="#"]').click(function(e){
			e.preventDefault();
		});
	});

	var hash = window.location.hash;
	try {
		if (hash) {
			scrollTo.root.animate({
				scrollTop: jQuery(hash).offset().top - 90
			}, 500);
		}
	} catch (err) {}

	var windowHeight = $(window).outerHeight(),
	height = $('#main-header').offset().top;
	$(document).on("scroll", function(){
		var scrollPos = $(document).scrollTop();
		$('.site-nav a').each(function() {
			var currLink = $(this);
			if( currLink.attr("href") === "#" || currLink.attr("href").indexOf( '#' ) === -1 || currLink.attr("href").indexOf( '#' ) > 0 ) {
				return;
			} else {
				var refElement = $(currLink.attr("href"));
				var elementTop = refElement.position().top;
				if (elementTop - 180 <= scrollPos && elementTop - 180 + refElement.height() > scrollPos) {
					$('.navbar-nav a, .site-navigation .nav a').removeClass("active");
					currLink.addClass("active");
				} else {
					currLink.removeClass("active");
				}
			}
		});
	});

	// load other inofs after page load
	var lazyLoadStatus = true;
	$(window).on('scroll resize', function() {
		var top = $(window).scrollTop(),
			headerHeight = $('#main-header').outerHeight(),
			bannerHeight = $('#banner').outerHeight();
		if (top > 50) {
			$('body').addClass('active-fixed');
		} else {
			$('body').removeClass('active-fixed');
		}
		// check scrooled from top 100px then load other infos
		/*if( pageYOffset > 100 && lazyLoadStatus === true ){
			$('#about').css('backgroundImage', 'url(images/bg/about-bg.jpg)');
			$('#contact').css('backgroundImage', 'url(images/bg/contact-bg.jpg)');
			$('body').append("<script type='text/javascript' src='js/jquery.validate.min.js'></script>");
			$("#map").append('<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.66662829612!2d139.7673323152589!3d35.68520978019333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bfee20f4c87%3A0xd363ff4d2c4b621f!2z44CSMTAwLTAwMDQg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy65aSn5omL55S677yS5LiB55uu77yW4oiS77ySIOaXpeacrOODk-ODqzEyRg!5e0!3m2!1sja!2sjp!4v1538206864066" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>');
			validateForm("#contact_form form");
			lazyLoadStatus = false;
		}*/
	});

	// handle collapse bar
	$('.collapse-bar').on('click', function(e) {
		e.preventDefault();
		$(this).toggleClass('open');
		$('body').toggleClass('active-menu');
	});

	$('.collapse-bar').on('click', function() {
	var state = $(this).attr('aria-expanded') === 'false' ? true : false;
	$(this).attr('aria-expanded', state);
	});

	// dropdown menu
	$('.dropdown').click(function(e){
		$(this).toggleClass('open');
	});
	// close dropdown when triggers other element
	$('html, body').click(function(e){
		if( e.target.classList[0] !== "dropdown" ){
			$('.dropdown').removeClass('open');
		}
	});

	function sendContact() {
        var valid, form;
        form = $('#contact_form form');
        valid = form.valid();
        if(valid) {
            jQuery.ajax({
                url: form.attr('action'),
                data:'name='+$("#name").val()+'&email='+
                $("#email").val()+'&subject='+
                $("#subject").val()+'&content='+
                $('#content').val(),
                type: "POST",
                success:function(data){
                    form[0].reset();
                    $("#mail-status").html(data);
                    setTimeout(function(){
                        $('#mail-status p').slideUp('slow');
                    }, 5000);
                    window.scrollTo(0, $("#mail-status").offset().top - 100);
                }
            });
        }
    }
    $('#contact_form form').submit(function(e){
        e.preventDefault();
        sendContact();
        $(this).find(':submit').attr('disabled', 'disabled');
    });


})( jQuery, window, document );

